@import '../../styling/global_variables.scss';

.axis {
	stroke-width: 1px;

	&.removeFirstandLast {
		.tick:first-of-type, .tick:last-of-type {

			text{
				display: none;
			}
		}
	}

	&.grid{
		.tick:first-of-type {

			line{
				stroke: map-get($colors, grid-dark-grey);
			}
		};
	}

	&.bottom-ticks{
		.tick line{
			stroke: map-get($colors, grid-dark-grey);
		}
	}

	.tick {

		text {
			font-family: 'Work-Sans-Medium';
			font-size: 10px;
		  fill: map-get($colors, secondary);
		}
		line {
			stroke: map-get($colors, grid-light-grey);
		}
	}

	path.index {
		stroke: map-get($colors, index) !important;
	}

}