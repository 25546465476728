@import '../../styling/global_variables.scss';

.ColorScale {

	display: flex;
	justify-content: space-between;
	min-width: 100px;
	margin: 0px 3px;

	.colorTick {
		color: map-get($colors, white);
		padding-left: 5px;
		padding-right: 5px;
		margin: auto 0;
	}

	.center {
		color: map-get($colors, tertiary);
	}

}