@import "../../styling/global_variables.scss";

.ThreeInOne {
  color: map-get($colors, main);

  &.disablePointer {
    pointer-events: none;
  }

  .highlight {
    outline: 2px solid map-get($colors, main);
  }

  .cardLabelParent {
    transition: opacity 300ms;
    pointer-events: none;

    &.bar {
      margin: auto;
      height: 100%;
      width: 100%;
    }
    &.hide {
      opacity: 0;
    }
  }

  .cardLabel {
    margin: 5px 5px 0px 5px;
    font-family: "Work-Sans-SemiBold";
    font-size: 18px;

    &.bar {
      margin: 2px auto;
      font-size: 12px;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
  }
  .cardSubLabel {
    margin: 0px 5px 5px 5px;
    font-family: "Work-Sans-Medium";
    font-size: 10px;
    text-transform: capitalize;
    overflow: hidden;
    height: 2em;
    &.hide {
      opacity: 0;
    }
  }

  .card {
    position: absolute;
    transition-duration: $cardIntro-duration;
    opacity: 1;

    &.scrolledDown {
      cursor: pointer;
      transition-property: top, left, width, height, opacity, background-color,
        font-size;
      transition-delay: 0ms !important;
    }

    &.colorError {
      background-color: map-get($colors, grid-light-grey) !important;

      .cardLabel,
      .cardSubLabel {
        color: map-get($colors, grid-dark-grey);
      }
    }

    &:not(.scrolledDown) {
      opacity: 0.7;
      pointer-events: none;

      &.fade-appear {
        transition: opacity 5ms ease;
        opacity: 0.01;
      }

      &.fade-appear-active {
        transition: opacity 5ms ease;
        opacity: 0.7;
      }

      &.fade-exit {
        transition: opacity 5ms ease;
        opacity: 0.7;
      }

      &.fade-exit-active {
        transition: opacity 5ms ease;
        opacity: 0.01;
      }
    }
  }

  svg.tooltip-wrapper {
    pointer-events: none;
    transition-property: transform;
    transition-duration: 300ms;
  }

  svg.axis-wrapper {
    position: absolute;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 300ms;
    pointer-events: none;

    &.visible {
      opacity: 1;
    }
  }
}

/* for tooltip activation */
.ThreeInOne:hover {
  .tooltip-wrapper.hasData {
    opacity: 1;
  }
}

@media (max-width: $min-width), (max-height: $min-height) {
  .ThreeInOne {
    .cardLabel {
      font-size: 12px;
    }
  }
}
