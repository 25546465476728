@import "../../styling/global_variables.scss";

.Viz {
  display: grid;
  grid: inherit;
  grid-row-start: 2;
  grid-row-end: end;
  grid-column-start: 1;
  grid-column-end: end;
  height: calc(100vh - #{$header-height});
  overflow-y: hidden;

  .links {
    margin: 20px 0px;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;

    a {
      color: map-get($colors, main);
      text-decoration: none;
      font-family: "Work-Sans-SemiBold";

      img {
        padding: 0px 5px;
        vertical-align: text-top;
      }
    }
  }

  .components {
    overflow-y: scroll;
    overflow-x: hidden;
    grid-column-start: 1;
    grid-column-end: 2;
    display: inline-block;
    margin: 10px 20px;
    min-width: 330px;

    .titleDiv {
      display: grid;
      grid-template-columns: 1fr minmax(210px, 1fr);
      margin: 40px 0px 0px 0px;
      width: 100%;
      overflow: hidden;
      // overflow:show;

      .title {
        margin-right: 5px;
      }
    }

    .HoldingsTable {
      display: inline-block;
      width: 100%;
    }
  }

  .performance {
    grid-column-start: 2;
    grid-column-end: end;
    overflow-y: scroll;
    overflow-x: hidden;
    display: inline-block;
    margin: 10px 20px;

    div.disablePointer {
      pointer-events: none;
    }

    .titleDiv {
      display: grid;
      grid-template-columns: 2fr minmax(210px, 1fr);
      margin: 40px 0px 0px 0px;
      width: 100%;
      overflow: hidden;
    }

    .Ticker {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    .ColorScale {
      font-family: "Work-Sans-Medium";
      float: right;

      .axis path {
        stroke: none;
      }

      .axis text {
        font-family: "Work-Sans-SemiBold";
        font-size: 12px;
        fill: #ffffff;
      }
    }

    .selections {
      font-size: 12px;
      border-top: 4px solid #ffffff;
      border-bottom: 4px solid #ffffff;
      line-height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-transform: uppercase;
      padding: 3px 0px;
      margin-bottom: 10px;

      .selection-label {
        margin: 0px 4px 0px 0px;
        color: map-get($colors, tertiary);
      }

      .selection-group {
        span.Button {
          margin: 0px 4px;
        }

        span.Button:after {
          content: "";
          height: 10px;
          border-left: 2px solid;
          color: map-get($colors, main);
          position: relative;
          left: 9px;
        }

        span.Button:last-child:after {
          opacity: 0;
        }
      }
    }

    .placeholder {
      height: $ThreeInOne-height;
    }

    .ThreeInOne {
      height: $ThreeInOne-height;
    }

    .timespan-and-colors {
      margin-top: 12px;
    }
  }
}

@media (max-width: $min-width), (max-height: $min-height) {
  .Viz {
    grid-template-columns: 1fr;
    overflow-y: scroll;
    height: calc(100vh - #{$mobile-header-height});
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .performance {
      grid-column-start: unset;
      grid-column-end: unset;
      grid-row-start: 1;
      grid-row-end: 2;
      overflow-y: hidden;
      height: fit-content;
      order: 0;

      .placeholder {
        height: 0px;
      }

      .ThreeInOne {
        height: $mobile-ThreeInOne-height;
      }

      .selections {
        display: grid;
        grid-template-columns: 1fr;
      }

      .timespan-and-colors {
        margin-top: 12px;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 15px;

        .timespan {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
        }
      }
    }

    .components {
      grid-column-start: unset;
      grid-column-end: unset;
      grid-row-start: 3;
      grid-row-end: 4;
      overflow-y: hidden;
      height: fit-content;
      order: 1;
    }

    .performance.titleDiv,
    .components.titleDiv {
      margin: 10px 0px 10px 0px;
      grid-template-columns: 1fr;

      .subtitle.updated {
        text-align: left;
      }
    }
  }
}
