@import "../../styling/global_variables.scss";

.HoldingsTable {
  /* Default grid */
  .header,
  .summary,
  .expandable {
    display: grid;
    grid-template-columns:
      minmax(55px, 1fr) minmax(65px, 2fr) minmax(50px, $sparkline-width)
      minmax(35px, 61px);
    grid-column-gap: 15px;
  }

  /* HEADER */
  .header {
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    line-height: 28px;
    border-top: 4px solid map-get($colors, white);
    border-bottom: 4px solid map-get($colors, white);
    padding: 3px 10px;

    .arrow {
      position: relative;
    }
    /*basic arrow setup*/
    .arrow:before,
    .arrow:after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      right: -7px;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
    }

    .ticker,
    .name,
    .weight {
      cursor: pointer;
    }

    /*down arrow*/
    .arrow:before {
      border-top: 4px solid;
      top: 50%;
    }

    /*up arrow*/
    .arrow:after {
      border-bottom: 4px solid;
      top: calc(50% - 5px);
    }
  }

  /* TICKER ROW */
  .ticker-row {
    padding: 3px 10px;
    border-bottom: 1px solid map-get($colors, white);
    overflow: auto;
    cursor: pointer;

    path {
      fill: none;
      stroke-width: 1px;
      stroke: map-get($colors, main);
    }

    svg {
      padding: 2px;
    }

    .summary > div {
      align-self: center;

      .ticker > span {
        float: left;
      }
    }

    /*right pointing arrows*/
    .ticker:before {
      content: "";
      position: relative;
      float: left;
      top: 6px;
      left: -4px;
      width: 0;
      height: 0;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 4px solid;
      transition: transform 200ms;
      z-index: 1;
    }

    .compName {
      text-transform: capitalize;
      text-decoration: underline;
      font-family: "Work-Sans-Medium";
      font-size: 12px;
      color: map-get($colors, secondary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .weight {
      font-size: 14px;
      font-family: "Work-Sans-Medium";
    }

    circle {
      fill: map-get($colors, main);
    }

    .expandable {
      color: map-get($colors, tertiary);
      max-height: 0;
      overflow: hidden;
      transition: max-height 500ms;
      overflow: auto;
      /*z-index: -1;*/

      .description {
        grid-column-start: 2;
        grid-column-end: 5;
        border-top: 1px solid map-get($colors, white);
        padding-top: 5px;
        padding-bottom: 10px;
        font-size: 12px;
        font-family: "Work-Sans-Regular";
      }

      .logo {
        grid-column-start: 1;
        grid-column-end: 2;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .ticker-row.highlight {
    circle {
      fill: map-get($colors, white);
    }

    path {
      stroke: map-get($colors, white);
      stroke-width: 1px;
    }

    .compName {
      color: map-get($colors, white);
    }

    background-color: map-get($colors, main);
    color: map-get($colors, white);
  }

  /* when collapsable row open */
  .ticker-row.open {
    .compName {
      overflow: hidden;
      text-overflow: unset;
      white-space: unset;
    }

    .expandable {
      max-height: 1000px;
    }

    .ticker:before {
      transform: rotate(90deg);
    }
  }

  /* specific column styling */
  .weight {
    text-align: right;
  }
}
