@import '../../styling/global_variables.scss';

.tooltip {
	fill: inherit;
	pointer-events: none;

	transition-property: opacity, fill;
	transition-duration: 200ms;

	&.visible {
		opacity: 1;
	}

	.arrow {
		fill: inherit;
		stroke: 'none';
	}

	.text {
		fill: map-get($colors, white);
		font-family: 'Work-Sans-SemiBold';
		font-size: 12px;
		text-anchor: end;

		&.metric-name{
			font-family: 'Work-Sans-SemiBold';
			fill: map-get($colors, tertiary);
			text-transform: uppercase;
			font-size: 11px;
			letter-spacing: -0.03em;
		}

		&.name{
			font-size: 14px;
			text-anchor: start;
		}

		&.metric{
			font-size: 14px;
		}
	}

}

.tooltip-wrapper{
	fill: map-get($colors, main);
	opacity: 0;

	&.visible {
		opacity: 1;
	}

	.index {
		fill: map-get($colors, index);
	}
}