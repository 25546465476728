@import "../../styling/global_variables.scss";

.Landing {
  display: grid;
  grid: inherit;
  height: 100vh;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: end;
  position: relative;

  .introTitle {
    position: absolute;
    display: flex;
    font-family: "Work-Sans-Bold";
    font-size: 74px;
    text-transform: uppercase;
    color: map-get($colors, white);
    margin: 0;
    width: 100%;
    height: 100vh;
    max-width: $max-width;
    text-align: center;
    align-items: center;
    justify-content: center;
    transition: opacity $introText-duration;

    &.introDone {
      opacity: 0;
    }
  }

  .introText {
    font-family: "Work-Sans-Bold";
    position: absolute;
    font-size: 20px;
    grid-column-start: 2;
    grid-column-end: end;
    opacity: 0;
    margin-left: 20px; /* needs to mirror the three-in-one viz */
    margin-right: 20px;
    color: map-get($colors, white);
    text-shadow: $text-outline 0 0 5px;
    text-align: left;
    vertical-align: middle;
    transition-property: opacity;
    transition-duration: $introText-duration;
    transition-delay: $cardIntro-duration; /* wait until all the cards are created then create this */
    top: 500px;
    pointer-events: none;

    &.small-text {
      font-size: 16px;
    }

    &.introDone {
      opacity: 1;
    }

    &.overlayVisible {
      transition-delay: 0ms !important;
      opacity: 0;
    }
  }

  .Header {
    font-family: "Work-Sans-SemiBold";
    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1%;
    margin: auto;
    height: $header-height;
    max-width: $max-width;
    min-width: $min-width;
    width: 100%;
    z-index: 1;
    text-shadow: $text-outline 0 0 10px;

    .fundDetails {
      grid-column-start: 1;
      grid-column-end: 2;
      min-width: 380px;
      color: map-get($colors, white);
      display: inline-block;
      margin: 20px 50px;

      .name {
        width: 100%;
        padding-bottom: 5PX;
        font-size: 20px;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        border-bottom-color: map-get($colors, white);
        transition-property: border-bottom-color, color;
        transition-duration: $overlay-duration;
      }
      .fund,
      .close {
        font-size: 45px;
        transition-property: color, font-size, letter-spacing;
        transition-duration: $overlay-duration;
        font-weight: 600;
        &.longTicker {
          font-size: 40px;
        }
      }
      .close {
        float: right;
      }
      .change {
        transition-property: color, background-color;
        transition-duration: $overlay-duration;
        float: right;
      }

      &.overlayVisible {
        .fund,
        .close {
          font-size: 40px;
          color: map-get($colors, accent);
          transition-property: color, font-size;
          transition-duration: $overlay-duration;
        }

        .name {
          color: map-get($colors, accent);
          transition-property: border-bottom-color, color;
          transition-duration: $overlay-duration;
          border-bottom-color: map-get($colors, accent);
        }

        .change {
          color: map-get($colors, white);
          transition-property: color, background-color;
          transition-duration: $overlay-duration;
        }
      }
    }

    div.logo {
      grid-column-start: 3;
      grid-column-end: end;
      position: relative;
      align-self: center;

      #prime-index-logo {
        width: 180px;
        fill: map-get($colors, white);
        transition-property: fill, width;
        transition-duration: $overlay-duration;
        float: right;
        /*position: absolute;*/
      }

      .overlayVisible#prime-index-logo {
        width: 160px;
        fill: map-get($colors, accent);
      }
    }
  }
}

/*media queries*/

@media (max-width: $min-width), (max-height: $min-height) {
  .Landing {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(100px, 125px) max-content;
    grid-column-gap: 1%;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    min-width: unset;
    /*overflow-y: hidden;*/

    .Header {
      grid-row-start: 1;
      grid-row-end: 2;
      display: grid;
      grid-template-columns: minmax(200px, 300px) 1fr;
      width: 100%;
      min-width: unset;
      max-width: 100vw;
      height: $mobile-header-height;
      top: 0px;

      .fundDetails,
      .fundDetails.overlayVisible {
        grid-column-end: 2;
        min-width: 100px;
        align-self: center;
        margin: 16px 10px 0px 25px;
        transition: height $overlay-duration;

        .fund,
        .fund.longTicker,
        .close,
        .close.longTicker {
          font-size: 20px;
          letter-spacing: 1px;
          transition: color $overlay-duration;
          line-height: 30px;

          &.overlayVisible {
            font-size: 15px;
            letter-spacing: 0px;
          }
        }

        .close {
          margin-left: 0.5em;
        }

        .change {
          opacity: 0;
        }

        .name {
          font-size: 12px;
          border-bottom-width: 3px;
          transition: border-bottom-color height color $overlay-duration;
        }
      }

      .overlayVisible {
        .name {
          padding: 0;
          height: 0px;
          overflow: hidden;
        }
      }

      div.logo {
        max-width: 90px;
        svg#prime-index-logo.overlayVisible {
          margin-right: 1em;
        }
      }
    }

    .introTitle {
      opacity: 0;
    }

    .introText {
      grid-column-start: 1;
      grid-column-end: end;
      grid-row-start: 2;
      grid-row-end: 3;
      margin: unset;
      padding: 1em;
      top: unset;
      width: auto;
      font-size: 20px;
      overflow-y: scroll;
    }
  }
}
