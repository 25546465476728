@import "../../styling/global_variables.scss";

.StatsTable {
  margin-top: 30px;

  .indexStats,
  .componentStats {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    width: 100%;
    margin: 10px 0px;

    img {
      display: inline-block;
      margin: 2px 10px;
      width: 15px;
      cursor: pointer;

      &.hide {
        margin: 1px 10px;
        height: 12px;
      }
      &.del {
        height: 12px;
        width: 12px;
      }
    }

    &.hide {
      span {
        opacity: 0.5;
      }
    }

    .indexDetails,
    .componentDetails {
      grid-column-start: 1;
      grid-column-end: 3;

      .indexDetailsRow,
      .componentDetailsRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .indexDetailsRow {
        color: map-get($colors, index);
      }

      .index,
      .close,
      .component {
        font-family: "Work-Sans-SemiBold";
        font-size: 24px;
      }

      .statsName {
        font-family: "Work-Sans-Medium";
        font-size: 14px;
      }

      .change {
        font-family: "Work-Sans-Medium";
        font-size: 14px;
        text-align: right;
      }
    }

    .tableContainer {
      grid-column-start: 3;
      grid-column-end: end;
      display: inline-block;
      width: auto;
      min-width: 400px;
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 10px 0px;

      &.indexTable {
        width: 66.6%;

        td {
          width: 46%;
        }

        .statsRowData {
          color: map-get($colors, index);
        }
      }

      tr {
        display: flex;
        justify-content: space-between;
      }

      td {
        width: 30%;
        line-height: 20px;
        border-top: 1px solid map-get($colors, tertiary);
        border-bottom: 1px solid map-get($colors, tertiary);

        &.top {
          border-bottom: 0px;
        }
      }

      .statsRowLabel {
        font-family: "Work-Sans-Medium";
        font-size: 12px;
        color: map-get($colors, secondary);
      }

      .statsRowData {
        font-family: "Work-Sans-SemiBold";
        font-size: 14px;
        float: right;
      }
    }
  }
}

@media (max-width: $min-width), (max-height: $min-height) {
  .StatsTable {
    margin: 0px;
    padding-top: 10px;

    .indexStats,
    .componentStats {
      grid-template-columns: 1fr;
      grid-row-gap: 15px;

      .tableContainer {
        min-width: unset;
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 2;
        grid-row-end: end;
        width: 100%;

        table {
          border-spacing: unset;

          .statsRowLabel {
            font-size: 10px;
          }

          .statsRowData {
            font-size: 12px;
            top: 1px;
            position: relative;
          }
        }
      }

      .indexDetails,
      .componentDetails {
        grid-column-start: 1;
        grid-column-end: end;
        grid-row-start: 1;
        grid-row-end: 2;
      }
    }
  }
}
