/*MICROSITE-SPECIFIC VARIABLES*/
@import "variables.scss";

/**APP-WIDE VARIABLES**/
$Work-Sans-Reg: "Work Sans 400";
$Work-Sans-Med: "Work Sans 500";
$Work-Sans-Semi: "Work Sans 600";

$header-height: 150px;
$mobile-header-height: 65px;
$max-width: 1380px;
$min-width: 768px;
$min-height: 440px;
$ThreeInOne-height: 225px;
$mobile-ThreeInOne-height: 300px;

/* transition durations */
$overlay-duration: 1000ms;
$intro-duration: 3000ms;
$scrollTimeout-duration: 10000ms;
$introText-duration: 500ms;
$cardIntro-duration: 800ms;
$scroll-duration: 700ms;

/* sparkline sizing */
$sparkline-width: 100px;

/* colors */
$colors: (
  background: #dee3ef,
  main: #19356d,
  secondary: #404d5a,
  tertiary: #878a8b,
  white: #ffffff,
  grid-light-grey: #d2d6dd,
  grid-dark-grey: #808080,
  /* site specific colors below */ green: $green,
  red: $red,
  accent: $accent-color,
  video-overlay: $video-overlay-color,
  index: $index-color,
);

:export {
  main: map-get($colors, main);
  accent: map-get($colors, accent);
  lightgrey: map-get($colors, background);
  green: map-get($colors, green);
  red: map-get($colors, red);
  white: map-get($colors, white);
  headerHeight: $header-height;
  maxWidth: $max-width;
  minWidth: $min-width;
  minHeight: $min-height;
  introDuration: $intro-duration;
  scrollTimeout: $scrollTimeout-duration;
  cardDuration: $cardIntro-duration;
  scrollDuration: $scroll-duration;
  sparklineWidth: $sparkline-width;
  ThreeInOneHeight: $ThreeInOne-height;
  mobileHeaderHeight: $mobile-header-height;
  mobileThreeInOneHeight: $mobile-ThreeInOne-height;
}
