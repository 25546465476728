@import '../../styling/global_variables.scss';

.Ticker {

	.domain {
	  stroke: map-get($colors, grid-dark-grey);
		stroke-width: 1px;
	  fill: none;
	}

	.tickerLine {
	  stroke: map-get($colors, main);
		stroke-width: 2px;
	  fill: none;

		&.noDataChange {
			filter: none;
		}
	  &.index {
	  	stroke: map-get($colors, index)
	  }
	}

	.vertLine, .zero {
	  stroke: map-get($colors, grid-dark-grey);
		stroke-width: 1px;
	}

	.area {
		fill: map-get($colors, white);
		opacity: 0;
		stroke: none;
		transition-duration: 500ms;
		transition-property: opacity;

		&.visible {
			opacity: 0.5;
		}
	}

	.bar {
		fill: map-get($colors, main);
		max-width: 5px;
	}

	.tooltip-wrapper {

		pointer-events: none;

		.tooltipGroup, .tooltipAccessory, circle {
			transition-property: transform;
			transition-duration: 300ms;
		}

		.circle {
			fill: map-get($colors, main);
			stroke: map-get($colors, white);
		}

	}
}