@import "global_variables.scss";

body {
  background-color: map-get($colors, background);
  font-family: "Work-Sans-SemiBold", "Times New Roman", sans-serif;
  font-size: 14px;
  color: map-get($colors, main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  cursor: default;
  overflow-y: hidden;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1%;
  grid-template-rows: auto;
  max-width: $max-width;
  min-width: $min-width;
  width: 100%;
  margin: auto;
  height: calc(200vh - #{$header-height});

  .landingViz {
    pointer-events: none;
    position: fixed;
    left: 500px;
    top: 300px;
    grid-column-start: 2;
    grid-column-end: end;
  }
}

.curly {
  position: absolute;
  fill: #ffffff;
  stroke-width: 10;
  stroke-miterlimit: 10;
  transition: fill $overlay-duration;
  width: 60px;
  height: 60px;
  &.overlayVisible {
    fill: map-get($colors, accent);
  }
  &.topLeft {
    top: 0px;
    left: 0px;
  }
  &.topRight {
    transform: scaleX($icon-rotate);
    top: 0px;
    right: 0px;
  }
  &.bottomLeft {
    transform: scaleY($icon-rotate);
    bottom: 0px;
    left: 0px;
  }
  &.bottomRight {
    transform: scaleY($icon-rotate) scaleX($icon-rotate);
    bottom: 0px;
    right: 0px;
  }
}

.title {
  color: map-get($colors, main);
  font-size: 24px;
  position: relative;
  align-self: end;
  bottom: 5px;
}

.subtitle {
  color: map-get($colors, tertiary);
  font-family: "Work-Sans-Medium";
  font-size: 12px;
  align-self: end;
  position: relative;
  bottom: 5px;

  &.updated {
    text-align: right;
    bottom: 8px;
  }
}

table {
  border-color: map-get($colors, grid-light-grey);
}

@media (max-width: $min-width), (max-height: $min-height) {
  body {
    -webkit-overflow-scrolling: touch;
  }

  .container {
    position: fixed;
    min-width: unset;
    height: calc(calc(var(--vh, 1vh) * 200) - #{$mobile-header-height});
    &.overlayVisible {
      top: calc(#{$mobile-header-height} - 100vh);
    }
  }

  .curly {
    position: fixed;
    width: 40px;
    height: 40px;

    &.overlayVisible {
      &.topRight,
      &.topLeft {
        display: none;
      }
      &.bottomRight,
      &.bottomLeft {
        top: $mobile-header-height - 30;
        width: 30px;
        height: 30px;
      }
    }
  }
}
