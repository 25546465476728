@import "../../styling/global_variables.scss";

.Video {
  position: absolute;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;

  .videoPlayer,
  img {
    object-fit: fill;
    mix-blend-mode: overlay;
    filter: none;
    transition: filter $overlay-duration;

    &.overlayVisible {
      filter: grayscale(100%) brightness(1.3) saturate(0.8) contrast(0.7);
      transition: filter $overlay-duration;
    }
  }

  .overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: transparent;
    transition: background-color $overlay-duration;

    &.overlayVisible {
      background-color: map-get($colors, video-overlay);
      transition: background-color $overlay-duration;
      z-index: 1;
      mix-blend-mode: $video-blend-mode;

      // fix for MS Edge not supporting blend mode
      @supports not (mix-blend-mode: $video-blend-mode) {
        opacity: 0.3;
      }
    }
  }
}

/*media queries*/

@media (max-width: $min-width), (max-height: $min-height) {
  .Video {
    overflow: hidden;
    background: url("../../assets/mobile-img.jpg") center;

    .imagePlaceholder {
      min-height: 100vh;
      min-width: 100vw;
    }
  }
}
