
@import '../../styling/global_variables.scss';

.Button {
	display: inline-block;
	text-align: center;
	color: map-get($colors, main);
	line-height: 20px;
	padding: 0px 4px;
	margin: auto;
	cursor: pointer;

	&.disabled {
		color: map-get($colors, grid-dark-grey);
		pointer-events: none;
	}

	&.selected {
		background-color: map-get($colors, main);
		color: map-get($colors, white);
	}
}